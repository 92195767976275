import detectDeviceInfo from "../deviceInfo";

const deviceInfo = detectDeviceInfo();

const initialState = {
  ...deviceInfo,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    default: {
      return state;
    }
  }
};
