import React from "react";
import { useSelector } from "react-redux";
import Home from "./views/Home";

import styles from "./app.module.scss";

const App = () => {
  const { browserInfo } = useSelector((state) => state);
  if (!browserInfo.isValidBrowser) {
    return <div>你使用的瀏覽器無法支援該服務</div>;
  }

  return (
    <div className={styles.app}>
      <Home />
    </div>
  );
};

export default App;
