import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import styles from "./index.module.scss";
const socketPromise = require("../../socket.io-promise").promise;

// const streamId = "meshub";
// const ENDPOINT = `https://rtc-to-rtmp-server.meshstream.io?streamId=${streamId}`;

// const meshubSocket = io(generateSocketUrl({ streamId: "meshub" }));
// const ytSocket = io(generateSocketUrl({ streamId: "yt" }));
let stream = null;
let recorder = null;
const chunks = [];
const fbRtmpUrl = "rtmps://live-api-s.facebook.com:443/rtmp";

const fbSocket = io(generateSocketUrl({ streamId: "fb2" }), {
  transports: ["websocket"],
});
fbSocket.request = socketPromise(fbSocket);

function generateSocketUrl({ streamId }) {
  return `https://rtc-to-rtmp-server.meshstream.io?streamId=${streamId}`;
}

let isPublishing = false;

const Home = () => {
  const localVideoRef = useRef();
  const { browserInfo } = useSelector((state) => state);
  const [byteCount, setByteCount] = useState(0);
  // const [meshubRtmpUrl, setMeshubRtmpUrl] = useState(
  //   "rtmp://rtmp-tw-gcp.meshstream.io/live/meshub"
  // );
  // const [ytRtmpUrl, setYtRtmpUrl] = useState(
  //   "rtmp://x.rtmp.youtube.com/live2/1mgm-xzxz-d4fs-ctzq-283r"
  // );
  const [fbKey, setFbKey] = useState("FB-10165790037545111-0-Abxm2-iALUUU8brn");
  // const [fbKey, setFbKey] = useState("");

  useEffect(() => {
    // meshubSocket
    //   .on("reconnect", () => {
    //     console.log("socket reconnect");
    //     if (isPublishing) {
    //       console.log("republish");
    //       rtcToRtmp();
    //     }
    //   })
    //   .on("unauthorized", (data) => {
    //     console.log("unauthorized", data);
    //   })
    //   .on("rtcToRtmp:success", (data) => {
    //     console.log("FB rtcToRtmp:success", data);
    //     startRecording();
    //     isPublishing = true;
    //   })
    //   .on("rtcToRtmp:failed", (data) => {
    //     console.log("rtcToRtmp:failed", data);
    //   });

    // ytSocket
    //   .on("unauthorized", (data) => {
    //     console.log("unauthorized", data);
    //   })
    //   .on("rtcToRtmp:success", (data) => {
    //     // meshubSocket.emit('rtcToRtmp:dataChunk')
    //     console.log("YT rtcToRtmp:success", data);
    //   })
    //   .on("rtcToRtmp:failed", (data) => {
    //     console.log("YT rtcToRtmp:failed", data);
    //   });

    fbSocket
      .on("disconnect", (reason) => {
        console.log("FB2 disconnect", reason);
        console.log("disconnect reason", reason);
        console.info("FB2 disconnect", reason);
        console.info("disconnect reason", reason);
        setByteCount(() => 0);
      })
      .on("reconnect", async () => {
        console.log("socket reconnect");
        console.info("socket reconnect");

        if (isPublishing) {
          await stopRecording();
          console.log("republish");
          console.info("republish");

          rtcToRtmp();
        }
      })
      .on("unauthorized", (data) => {
        console.log("unauthorized", data);
      })
      // .on("rtcToRtmp:success", (data) => {
      //   console.log("FB2 rtcToRtmp:success", data);
      //   startRecording();
      //   isPublishing = true;
      // })
      .on("rtcToRtmp:failed", (data) => {
        console.log("FB2 rtcToRtmp:failed", data);
        console.error("FB2 rtcToRtmp:failed", data);
      });
  }, []);

  useEffect(() => {
    if (localVideoRef.current && !stream) {
      getUserMedia();
    }
  }, []);

  async function getUserMedia() {
    try {
      if (browserInfo.platform !== "desktop") {
        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: 720,
            height: 1280,
            frameRate: { ideal: 30 },
          },
          audio: true,
        });
      } else {
        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: 1280 },
            height: { ideal: 720 },
            frameRate: { ideal: 30 },
          },
          audio: true,
        });
      }

      localVideoRef.current.srcObject = stream;
    } catch (error) {
      alert("getUserMedia error");
    }
  }

  const rtcToRtmp = async () => {
    // meshubSocket.emit("rtcToRtmp:start", {
    //   rtmpUrl: meshubRtmpUrl,
    //   ffmpeg_params: `-i - -acodec aac -vcodec libx264 -preset ultrafast -b:v 2M -g 30 -filter:v fps=30 -f flv ${fbRtmpUrl}/${fbKey}`,
    // });
    // ytSocket.emit("rtcToRtmp:start", {
    //   rtmpUrl: ytRtmpUrl,
    // });
    const { success } = await fbSocket.request("rtcToRtmp:start", {
      rtmpUrl: `${fbRtmpUrl}/${fbKey}`,
      ffmpeg_params: `-i - -acodec aac -vcodec libx264 -preset ultrafast -b:v 2M -g 30 -filter:v fps=30 -f flv ${fbRtmpUrl}/${fbKey}`,
    });

    console.log("rtcToRtmp:start success", success);
    if (success) {
      startRecording();
      isPublishing = true;
    }
  };

  // const pushToMeshub = () => {
  //   meshubSocket.emit("rtcToRtmp:start", {
  //     rtmpUrl: meshubRtmpUrl,
  //   });
  // };
  // const pushToYt = () => {
  //   ytSocket.emit("rtcToRtmp:start", {
  //     rtmpUrl: ytRtmpUrl,
  //   });
  // };
  // const pushToFb = () => {
  //   fbSocket.emit("rtcToRtmp:start", {
  //     rtmpUrl: fbRtmpUrl,
  //   });
  // };

  const stopRecording = async () => {
    console.log("stop");
    // meshubSocket.emit("rtcToRtmp:stop");
    // ytSocket.emit("rtcToRtmp:stop");
    recorder.stop(); // must stop recorder before rtcToRtmp:stop
    await fbSocket.request("rtcToRtmp:stop");

    isPublishing = false;
    setByteCount(() => 0);
  };

  const startRecording = () => {
    if (browserInfo.os === "ios") {
      recorder = new MediaRecorder(stream, {
        videoBitsPerSecond: 5000000,
        ignoreMutedMedia: true,
        mimeType: "video/mp4",
      });
    } else {
      recorder = new MediaRecorder(stream, {
        videoBitsPerSecond: 5000000,
        ignoreMutedMedia: true,
        mimeType: "video/webm",
      });
    }

    recorder.ondataavailable = async function (event) {
      console.log("ondataavailable 執行了");

      if (event.data.size > 0) {
        // chunks.push(event.data);
        // meshubSocket.emit("rtcToRtmp:dataChunk", event.data);
        // ytSocket.emit("rtcToRtmp:dataChunk", event.data);
        // fbSocket.emit("rtcToRtmp:dataChunk", event.data);
        console.log("ondataavailable > 0");
        const { byteCount } = await fbSocket.request(
          "rtcToRtmp:dataChunk",
          event.data
        );

        setByteCount(() => byteCount);
        console.log("byteCount", byteCount);
      }
    };

    recorder.onerror = function (event) {
      console.error("recorder.onerror", event.error.name);
    };

    recorder.start(100); // 如果沒給 timeslice，則 ondataavailable 在 recorder.stop() 時才會觸發
  };

  return (
    <div className={styles.home}>
      <div>
        <span>直播狀態: </span>
        <span className={`${byteCount ? styles.started : styles.stopped} `}>
          {byteCount ? "進行中" : "暫停中"}
        </span>
      </div>
      <span>Data chunk(byte): {byteCount}</span>
      <div>
        <video ref={localVideoRef} controls autoPlay muted playsInline />
      </div>
      <div>
        {/* <label htmlFor="meshubRtmpUrl">Meshub rtmpUrl</label>
        <InputText
          id="meshubRtmpUrl"
          name="meshubRtmpUrl"
          value={meshubRtmpUrl}
          onChange={(e) => setMeshubRtmpUrl(() => e.target.value)}
          autoComplete="off"
        /> */}
        {/* <Button
          type="button"
          label="推送"
          onClick={pushToMeshub}
          className="p-mt-2 primary"
        /> */}
      </div>
      <div>
        {/* <label htmlFor="fbRtmpUrl">YT rtmpUrl</label>
        <InputText
          id="ytRtmpUrl"
          name="ytRtmpUrl"
          value={ytRtmpUrl}
          onChange={(e) => setYtRtmpUrl(() => e.target.value)}
          autoComplete="off"
        /> */}
        {/* <Button
          type="button"
          onClick={pushToYt}
          label="推送"
          className="p-mt-2 primary"
        /> */}
      </div>
      <div>
        <label htmlFor="fbKey">FB stream key: </label>
        <InputText
          id="fbKey"
          name="fbKey"
          value={fbKey}
          onChange={(e) => setFbKey(() => e.target.value)}
          autoComplete="off"
        />
        {/* <Button
          type="button"
          onClick={pushToFb}
          label="推送"
          className="p-mt-2 primary"
        /> */}
      </div>
      <div>
        <Button
          type="button"
          onClick={rtcToRtmp}
          label="推送串流到 FB"
          className="p-mt-2 primary"
        />
        {/* <Button
        type="button"
        onClick={startRecording}
        label="start recording"
        className="p-mt-2 primary"
      /> */}

        <Button
          type="button"
          onClick={stopRecording}
          label="停止推送"
          className="p-button-danger p-mt-2"
        />
      </div>
    </div>
  );
};

export default Home;
